.app-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(circle farthest-corner at 0% 0%, #715aff, #a682ff);
  color: white;
}

.app-row{
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.upload-card{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #ff715b !important;
  border-radius: 32px !important;
}

.app-button{
  border-radius: 30px !important;
}

.upload-button{
  width: 120px;
  height: 50px
}

.result-text-field{
  border-radius: 5px;
  background-color: white;
  width: 90%;
  max-width: 800px;
}

.reset-button{
  margin-top: 15px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.danger-button{
  background-color: red !important;
  color: white !important;
  padding: 10px !important;
}

.success-button{
  background-color: rgb(45, 192, 0) !important;
  color: white !important;
  padding: 10px !important;
}